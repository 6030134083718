/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* color: #333333;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dragEnter {
  background-color: #f3f2f1;
}

#root, #root > div {
  width: 100%; 
  height: 100%;
}


@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI'), url('./fonts/segoeui-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'FabricMDL2Icons';
  src: url('./fonts/fabric-icons-a13498cf.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-0';
  src: url('./fonts/fabric-icons-0-467ee27f.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-1';
  src: url('./fonts/fabric-icons-1-4d521695.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-2';
  src: url('./fonts/fabric-icons-2-63c99abf.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-3';
  src: url('./fonts/fabric-icons-3-089e217a.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-4';
  src: url('./fonts/fabric-icons-4-a656cc0a.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-5';
  src: url('./fonts/fabric-icons-5-f95ba260.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-6';
  src: url('./fonts/fabric-icons-6-ef6fd590.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-7';
  src: url('./fonts/fabric-icons-7-2b97bb99.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-8';
  src: url('./fonts/fabric-icons-8-6fdf1528.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-9';
  src: url('./fonts/fabric-icons-9-c6162b42.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-10';
  src: url('./fonts/fabric-icons-10-c4ded8e4.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-11';
  src: url('./fonts/fabric-icons-11-2a8393d6.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-12';
  src: url('./fonts/fabric-icons-12-7e945a1e.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-13';
  src: url('./fonts/fabric-icons-13-c3989a02.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-14';
  src: url('./fonts/fabric-icons-14-5cf58db8.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-15';
  src: url('./fonts/fabric-icons-15-3807251b.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-16';
  src: url('./fonts/fabric-icons-16-9cf93f3b.woff') format('woff');
}

@font-face {
  font-family: 'FabricMDL2Icons-17';
  src: url('./fonts/fabric-icons-17-0c4ed701.woff') format('woff');
}

.ContextualMenuItemTypeHeader i.ms-ContextualMenu-checkmarkIcon,
.ContextualMenuItemTypeHeader i.ms-ContextualMenu-icon
 {
  display: none;
}
